import { useState, useEffect } from "react";
import "./styles/PopupAviso.css";
import { Trans } from "react-i18next";

const PopupAviso = () => {
  const [mostrarPopupAviso, setMostrarPopupAviso] = useState(false);

  useEffect(() => {
    setMostrarPopupAviso(true);
  }, []);

  const cerrarPopupAviso = () => {
    setMostrarPopupAviso(false);
  };

  return (
    <>
      {mostrarPopupAviso && (
        <div className="PopupAviso container-fluid">
          <div className="PopupAviso-contenido">
            <div className="PopupAviso-texto">
              <h2>
                <Trans>tituloPopUp</Trans>
              </h2>
              <p>
                <Trans>mensajePopUp</Trans>
              </p>

              <p>
                <Trans
                  i18nKey="InfoEveryNyk"
                  components={{
                    text_azul: <span className="texto-azul" />,
                    text_bold: <span className="semibold" />,
                  }}
                />
              </p>
              <p>
                <Trans i18nKey="contactosPopUp" components={{ text_azul: <span className="texto-azul" />, text_bold: <span className="semibold" /> }} />
              </p>
            </div>
            <div>
              <button className="btn btn-primary botonMobile" onClick={cerrarPopupAviso}>
                <Trans>botonAceptar</Trans>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupAviso;
